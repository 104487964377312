import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Resources from "../components/projects/Resources"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        resources: Array<{
          title: string
          description: string
          url: string
          image: string
          video?: boolean
        }>
        features: Array<{
          title: string
          description: string
        }>
      }
    }
  }
}

// markup
const CloudTemplate = (props: Props) => {
  const resources = props.data.page.frontmatter.resources
  const features = props.data.page.frontmatter.features
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className={`cloud event-driven`}
    >
      <div className="extra-0">
        <img src={`/img/extra/event-driven-7.svg`} alt="" />
      </div>
      <div className="container">
        <div className="hero-event-driven content py-6">
          <HTMLContent
            content={props.data.page.html}
            className={"markdown links-animate"}
          ></HTMLContent>
          <img className="image img-light" src={`/img/extra/cloud-1.svg`} alt="" />
          <img className="image img-dark" src={`/img/extra/cloud-1-dark.svg`} alt="" />
        </div>

        <div className="markdown content">
          <article className="columns has-big-border-dark-top pt-6 pb-5">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[0].title}
              </h1>
            </div>
            <div className="column is-8 pb-4">
              <div className="pt-2 pb-5">
                <img
                  className="img-light"
                  src={`/img/extra/cloud-3.svg`}
                  alt="Diagram"
                />
                <img
                  className="img-dark"
                  src={`/img/extra/cloud-3-dark.svg`}
                  alt="Diagram"
                />
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[1].title}
              </h1>
            </div>
            <div className="column is-8 links-animate">
              <HTMLContent
                className=""
                content={features[1].description}
              ></HTMLContent>
              <div className="pt-5">
                <Link to="/guides/gs/service-registration-and-discovery/">
                  Get started with this simple guide
                </Link>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top pt-6 pb-2">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[2].title}
              </h1>
            </div>
            <div className="column links-animate is-8 pl-5">
              <HTMLContent
                className=""
                content={features[2].description}
              ></HTMLContent>
              <div className="pt-5">
                <Link to="/blog/2019/06/18/getting-started-with-spring-cloud-gateway/">
                  Getting Started with Spring Cloud Gateway
                </Link>
              </div>
            </div>
          </article>
          <div className="quote mb-6">
            <div className="columns">
              <div className="column image is-4 pr-5 has-text-right-desktop">
                <a href="https://www.youtube.com/watch?v=khzC-VwpFVM">
                  <img src={`/img/extra/cloud-7.jpg`} alt="Cloud" />
                  <span className="ico">
                    <FontAwesomeIcon className="icon play-button" icon={faPlay} />
                  </span>
                </a>
              </div>
              <div className="column is-8">
                <div className="content">
                  <q className="is-size-4 has-text-weight-bold has-text-secondary">
                    We found that the performance of [Spring Cloud Gateway] was
                    very appealing. Low latency, good throughput, [and] a very
                    small percentage of timeouts in our use cases.
                  </q>
                  <div className="has-text-weight-bold pt-4">
                    CHRIS JACKSON, SENIOR DEVELOPER, TD AMERITRADE
                  </div>
                </div>
              </div>
            </div>
          </div>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[3].title}
              </h1>
            </div>
            <div className="is-8 links-animate">
              <div className="columns is-mobile">
                <div className="column is-4 pr-5 pl-6 has-text-right-desktop">
                  <img
                    className="img-light"
                    src={`/img/extra/cloud-2.svg`}
                    style={{ maxWidth: 150 }}
                    alt=""
                  />
                  <img
                    className="img-dark"
                    src={`/img/extra/cloud-2-dark.svg`}
                    style={{ maxWidth: 150 }}
                    alt=""
                  />
                </div>
                <div className="column is-8">
                  <HTMLContent
                    className=""
                    content={features[3].description}
                  ></HTMLContent>
                  <div className="pt-5">
                    <a href="/guides/gs/centralized-configuration/">
                      Try it now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[4].title}
              </h1>
            </div>
            <div className="column links-animate is-8">
              <HTMLContent
                className=""
                content={features[4].description}
              ></HTMLContent>
              <div className="pt-5">
                <Link to="/guides/gs/circuit-breaker/">
                  Try this guide to get started
                </Link>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[5].title}
              </h1>
            </div>
            <div className="column is-5 pr-6">
              <HTMLContent
                className="links-animate"
                content={features[5].description}
              ></HTMLContent>
            </div>
            <div className="column is-3">
              <div className="image">
                <a href="https://www.youtube.com/watch?v=CFLZJSwbYI0">
                  <img src={`/img/extra/cloud-4.png`} alt="Video" />
                  <span className="ico">
                    <FontAwesomeIcon className="icon play-button" icon={faPlay} />
                  </span>
                </a>
                <a
                  className="is-block mt-4 is-size-4 has-text-weight-bold"
                  href="https://www.youtube.com/watch?v=CFLZJSwbYI0"
                >
                  Spring Tips: Zipkin and Distributed Tracing
                </a>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[6].title}
              </h1>
            </div>
            <div className="column is-8 links-animate">
              <HTMLContent
                className=""
                content={features[6].description}
              ></HTMLContent>
              <div className="pt-5">
                <Link to="/guides/gs/contract-rest/">
                  Try this guide to get started
                </Link>
              </div>
            </div>
          </article>
        </div>

        <div className="has-background-light mt-6 p-6 has-text-centered">
          <div className="py-5">
            <h2 className="is-size-2 is-size-3-mobile has-text-weight-medium pb-6">
              Ready to get started?
            </h2>
            <Link
              className="button is-spring"
              to={`/2019/10/15/simple-event-driven-microservices-with-spring-cloud-stream`}
            >
              TRY THIS TUTORIAL
            </Link>
          </div>
        </div>

        <Resources resources={resources} />
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default CloudTemplate

// graphQL queries
export const pageQuery = graphql`
  query CloudTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        defaultClass
        resources {
          title
          description
          image
          url
          video
        }
        features {
          title
          description
        }
      }
    }
  }
`
